.swal2-styled.swal2-confirm
{
    background-color: #435cd2 !important;
    color: #ffffff !important;
    font-size: 14px !important;
}

.swal2-styled.swal2-confirm:hover
{
    background-color: #4057ca !important;
    color: #ffffff !important;
    font-size: 14px !important;
}

.swal2-styled.swal2-cancel
{
    color: #ffffff !important;
    font-size: 14px !important;
}

.swal2-title {
    font-size: 1.6em;
}
.swal2-html-container
  {
    padding-left: 60px !important;
    padding-top: 20px!important;
    text-align: left !important;
  }
  .swal2-html-container label
  {
    cursor:pointer;
    padding:10px;

  }
  .swal2-html-container label:hover
  {
    background-color: #d8d8d862;
    border-radius: 5px;
  }

  .larger-swal-button {
    width: 10em;
  }

  .centered-swal-text {
    text-align: center;
  }

  .larger-swal-html-container {
    padding-left: 0px !important;
    padding-top: 20px !important;
    text-align: center !important;
  }

  .less-margin-text-input {
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: 20rem !important;
  }

  .supplier-detail-swal-html-container {
    padding-left: 40px !important;
  }