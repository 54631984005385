.header {
  position: fixed;
  Height: 75px;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  box-shadow: 0px 13px 10px -15px gray;
  padding-bottom: 10px;
  width: 100%;
  z-index: 3;
  background-color: #435cd2;
}

@media only screen and (max-width: 1540px) {
  .header {
    height: 85px;
  }

}


.header__left {
  width: 50%;
  min-width: 490px;
  display: flex;
  float: left;
}

.header_logo_box {
  min-width: 190px;
  max-width: 190px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
}

.header__logo {
  width: 100%;
  object-fit: contain;
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
}

.header__search {
  height: 30px;
  width: 100%;
  margin-right: 15px;
  margin-left: 10px;
  min-width: 280px;
  margin-top: -4px;
}

.header__search>input {
  width: 300px;
  outline: none;
  border: none;
  background: none;
}

.search-components-wrap {
  padding-right: 10px;
}

.header__between {
  min-width: 150px;
  display: flex;
  max-width: 150px;
}

.header__right {
  display: flex;
  float: right;
}

.header__right_inside {
  float: right;
  display: flex;
  width: 100%;
}

.header__right_inside .MuiAvatar-colorDefault {
  background-color: #edf2f6;
}