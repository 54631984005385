.post {
    background-color: white;
    margin-bottom: 10px;
    /* height: 280px; */
    border-radius: 10px;
    /* border: 1px solid lightgray; */
    display: flow-root;
}



.admin-global {
    height: 180px;
}

#details-general {
    height: 230px;
}

#tax-general {
    height: 180px;
}

.top-head {
    min-height: 60px;
    padding: 10px;
}

#primary-contact,
#physical-address,
#secondary-contact {
    height: fit-content !important;
}

#company-contact {
    height: fit-content !important;
}

.post-left-content {
    float: left;
    margin-left: 10px;
}

.post__header {
    display: flex;
    border-bottom: 1px dashed lightgray;
    /* margin-left: 5px;
    margin-right: 5px;*/
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 10px;
}

.post_second_header {
    display: flex;
    background-color: #435cd2;
    color: white;
    padding: 4px;
    margin-bottom: 10px;
}

.post__section_header {
    display: flex;
    border-top: 1px dashed lightgray;
    border-bottom: 1px dashed lightgray;
    /* margin-left: 5px;
    margin-right: 5px;*/
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 20px 0;
}

.post__header>h2 {
    padding: 10px;
}

.post__company__info {
    margin-top: 5px;
    float: left;
    width: 100%;
}

.post_second_body {
    border: 1px solid lightgray;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: fit-content;
    margin: 10px;
}

.post__info>p {
    font-size: 12px;
    color: gray;
    margin-left: 20px;
}

.post__info>p>b {
    font-size: 14px;
    padding-right: 5px;
}

.post__info>h2 {
    font-size: 15px;
}

.head-left {
    margin: 10px;
    width: 50%;
    float: left;
}

.head-right {
    width: 50%;
    text-align: right;
    font-size: 20px;
    padding-top: 20px;
    padding-right: 20px;
    float: left;
    color: #435cd2;
}

.post_second_header .head-right {
    padding-top: 10px;
    padding-right: 10px;
    color: white;
}

table {
    margin: auto;
}

table td {
    padding: 10px;
}



table label {
    color: gray;
}

.hover-cursor {
    cursor: pointer;
}

.item {
    display: flex;
    align-items: center;
}

.item:last-child {
    border-bottom: 0;
}




.small-top-margin {
    margin-top: 5px;
}

.g-container {
    margin-bottom: 20px;
}


.select-holding {
    width: 100% !important;
}