.body {
  background-image: url("https://stdevb1ngnorthza001.blob.core.windows.net/signin-tests/b1background.png");
  background-size: cover;
  background-color: #171f28 !important;
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: 0;
}


.title {
  font-size: 18;
  text-align: "left";
  color: "white";
  margin-top: 60;
  margin-left: 150;
}

.title2 {
  font-size: 18;
  margin-top: 1;
  color: "white";
  margin-left: 150;
  text-align: "left";
}

.subtitle {
  font-size: 20;
  text-align: "justify";
  position: "absolute";
  color: "white";
  font-weight: 800;
}

.text {
  margin-bottom: 1;
  margin-top: 1;
  font-size: 14;
  text-align: "justify";
}


.subtext {
  margin-bottom: 1;
  margin-top: 1;
  width: "25%";
  font-size: 14;
  text-align: "center";
}

.image {
  margin-left: 30;
  position: "absolute";
  border-radius: "100%";
  width: "20%";
  background-color: "white";
  box-shadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)";
}

.header {
  font-size: 12;
  margin-bottom: 20;
  text-align: "center";
  color: "grey";
}


.sectionDetails {
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.sectionDetailsJoined {
  width: 100%;
  height: 170px;
}

.sectionDetailsLeft,
.sectionDetailsRight {
  width: 50%;
  padding: 10px;
  float: left;
}

.sectionDetailsFirstLeft {
  width: 80%;
  padding: 10px;
  float: left;
}

.sectionDetailsFirstRight {
  width: 20%;
  padding: 10px;
  float: left;
}


.section {
  background-color: #435cd2;
  padding: 10px;
  color: white;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sectionflex {
  padding: 10,
}


.sectionrow {
  flex-direction: "row",
}


.imagerating {
  margin-bottom: 1;
  margin-top: 1;
  position: "absolute";
  width: 20%;
}


.imageicon {
  align-self: "center";
  width: 5%;
}


.sectionfixed {
  position: "absolute";
  width: 100%;
  z-index: -1;
  background-color: "#435cd2";
  height: 100pt;
  margin-bottom: 100;
}

.mainsection {
  width: 100%;
  height: 180px;

}


.box {
  width: 100%;
  height: 190px;
}

.companyViewBox {
  margin-top: 15px;
  height: 130px;
  width: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 10px;
  background-color: #435cd2;
}

.imageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 10px;
}

.companyDetailsHeader {
  width: 100%;
  padding: 10px;
  margin-top: 160px;
  color: white;
  background-color: #435cd2;
}

.companyViewLeft {
  height: 90px;
  padding-top: 40px;
  color: white;
  float: left;
}

.companyViewRight {
  height: 90px;
  width: 200px;
  float: left;
}

.companyProfileImage {
  height: 150px;
  width: 150px;
  z-index: -99;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}