.popover {
  border-radius: 5px;
  float: right;
}

.popover div {
  width: 100%;
  height: fit-content;
  text-align: center;
  Padding: 20px;
}

.popover-welcome {
  border-radius: 5px;
  float: right;
  background-image: url(../images/Celebrate.gif);
}

.popover-welcome div {
  width: 100%;
  height: fit-content;
  text-align: center;
  Padding: 20px;
}

.popover-welcome div img {
  height: 200px;
  width: 200px;
}

.popover div img {
  height: 200px;
  width: 200px;
}

.popover .tour-start-btn {
  width: 50% !important;
  float: left;
}

.reactour__popover {
  border-radius: 5px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 40px !important;

}

.popover .tour-close-btn {
  width: 50% !important;
  float: left;
}

.popover-icon-btn {
  margin-top: -20px !important;
  margin-right: -25px !important;
  float: right;
}