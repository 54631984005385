.sidebar-box {
  width: 250px;
  float: left;
}


.card-image-2 {
  float: left;
  z-index: 1 !important;
  margin-top: -53px;
}

.profile-image-company-2 {
  height: 50px;
  width: 50px;
  background-image: linear-gradient(to bottom right, #589FE5, #435cd2);
  margin-left: 76px;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.dashboard-box {
  width: calc(100% - 270px);
  float: right;
  margin-bottom: 20px;
  border-radius: 4px;
}

.section-wrap-main {
  padding-top: 40px;
  width: 98%;
  margin: auto;
  height: fit-content;
}


.workcentre-agent-section {
  width: 100%;
  height: fit-content;
}


.work-centre-main-box {
  width: 100%;
  padding: 20px;
  height: fit-content;
  float: right;
  border-radius: 4px;
}

.work-centre-main-box-small {
  width: calc(100% - 270px);
  float: right;
  border-radius: 4px;
  margin-bottom: 10px;
}


.work-centre-main-box table td {
  width: 50%;
}

.work-centre-main-box .css-480o17-MuiGrid-root>.MuiGrid-item {
  padding-top: 10px !important;
}

.work-centre-main-box .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 20px !important;
}

.dashboard-grid-container .MuiGrid-item {
  text-align: center !important;
  width: 25% !important;
}

.dashboard-box .MuiTableRow-root:last-of-type .MuiTableCell-root {
  border: none !important;
}

.dashboard-box .MuiTableRow-root:nth-child(2) {
  background-color: rgba(0, 0, 0, 0.040);
}

.dashboard-box .MuiTableHead-root {
  background-color: #435bd2;
}

.dashboard-box .MuiTableCell-head {
  color: white !important;
}

.dashboard-grid-container .MuiPaper-outlined {
  border: none !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.work-centre-wrap {
  width: 100%;
  height: fit-content;
}

.paper-headings {
  Padding: 10px;
  margin-bottom: 10px;
}


.ico-clean-red {
  color: red !important;
  font-weight: 900;
  font-size: 1.1rem !important;
  margin-right: 5px;
}


.ico-clean-orange {
  color: orange !important;
  font-weight: 900;
  font-size: 1.1rem !important;
  margin-right: 5px;
}

.ico-clean-green {
  color: green !important;
  font-weight: 900;
  font-size: 1.1rem !important;
  margin-right: 5px;
}

/*SideBar suggentionCards*/
.suggestion-card {
  margin-top: 10px;
}

.suggestion-card:last-of-type {
  margin-bottom: 10px;
}

.align-suggestion-box-center {
  width: 100%;
}

.align-suggestion-box-center button {
  width: 100%;
  background-color: #435cd2 !important;
}

.icons-on-suggentions {
  font-size: 60px !important;
  color: rgba(0, 0, 0, 0.080);
}

.align-suggestion-box-right {
  width: 100%;
  text-align: right;
}

.suggestion-card .MuiTypography-body2,
.suggestion-card .MuiTypography-body2 p {
  text-align: center !important;
}


.suggestion-card .css-1ri6ub7-MuiPaper-root-MuiCard-root {
  max-width: unset !important;
}


.suggestion-card:last-of-type .css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 15px !important;
}

/*WorkCentre*/

.agent-list-button-switch {
  float: left;
  width: 100%;
  text-align: right;
}

.agent-list-search-switch {
  float: left;
  margin-bottom: 10px;
  width: 100%;
}

.agent-list-button-switch button {
  font-weight: 500 !important;
  background-color: #435cd2;
  margin-top: 0px;
}

.agent-list-box-small {
  width: 100%;
}

.agent-list-box {
  width: 100%;
  height: fit-content;
}

.agent-list-box .MuiDataGrid-root {
  height: 90%;
}

.dashboard-box .heading-sub-3 {
  margin-bottom: 10px;
}

.dashboardLeftContent {
  width: calc(100% - 350px);
  min-width: 800px;
  float: left;
}

.connectDashBox {
  height: 290px;
}

@media only screen and (max-width: 920px) {
  .sidebar-box {
    width: 100%;
  }

  .dashboard-box {
    width: 100%;
  }

  .work-centre-main-box {
    width: 100%;
  }


  .section-wrap-main {
    width: 95%;
  }
}

@media screen and (max-width: 750px) {
  .dashboard-box .css-1roptwa-MuiTypography-root {
    font-size: 9px;
  }

  .dashboard-grid-container h2 {
    font-size: 11px !important;
  }

  .dashboard-box .css-10wpov9-MuiTypography-root {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1522px) {
  .dashboardLeftContent {
    width: 100%;
  }

  .connectDashBox {
    height: 550px;
  }
}