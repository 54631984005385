.doc-table {
    width: 100% !important;
}

.doc-table-head {
    background-color:#435cd2;
    color: aliceblue;
    font-size: 15px;
    padding-left: 8px;
    padding-right: 8px;
}

.doc-table-heading-cell {
    color:White !important;
    white-space: nowrap;
}

.doc-table-content-cell {
    color:#000000 !important;
}

.doc-table-content-icon {
    color: #435cd2 !important;
}

.doc-table-content-icon-disabled {
    color: #4E545B !important;
}