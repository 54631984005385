.box {
  width: 100%;
}

.tourImage {
  margin: auto;
}

.tourSmallImage {
  margin: auto;
  width: 80px;
  height: 80px;
}

.imageBox {
  width: 100%;
  text-align: center;
  height: fit-content;
}

.imageSmallBox {
  width: 100%;
  height: 80px;
  text-align: center;
}

.link {
  color: white !important;
  text-decoration: none;
  padding: -5px !important;
}

.tourNeed {
  text-decoration: underline;
  color: #4369bc;
}

.tourNeed:hover {
  cursor: help;
}



.dialogBox {
  width: 100%;
  margin-top: 10px;
  height: fit-content;
  text-align: center;
}

.carouselContent {

  width: 97%;
  height: fit-content;
  margin: auto;
  padding: 50px;

}

.btnSide {
  width: 100%;
  margin-bottom: 10px;
  float: left;
}

.btnSide button {
  width: 200px;
}

.tourHeading {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 900 !important;
  text-shadow: 0px 0px 1px gray !important;
  font-size: 20px !important;
}

.tourHeadingLarge {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 900 !important;
  text-shadow: 0px 0px 1px gray !important;
  font-size: 30px !important;
}

.questionBtns {
  width: 100%;
  padding: 10px;
  margin-top: 10px !important;
}

.questionBtnsfree {
  font-size: 12px;
  width: 100%;
  padding: 0px;
  margin-top: 10px !important;
  border: 0px !important;
}

.questionBtnsfree:hover {

  text-decoration: underline;
  border: 0px !important;
  background-color: transparent !important;

}