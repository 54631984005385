.configListBtn {
  width: 100%;
  height: fit-content;
  text-align: right;
}

.btnBoxSub {
  width: 100%;
  height: fit-content;
  padding-right: 10px;
  padding-bottom: 10px;
  text-align: right;
}

.closeBtnSub {
  height: 30px;
  width: 100%;
  text-align: right;
}

.deleteIconBtn:hover {
  color: rgb(179, 31, 31) !important;
}

.activeValidationSwitchBox {
  width: 100%;
}

.activeValidationSwitchText {
  width: 90%;
  float: left;
}

.activeValidationSwitch {
  width: 10%;
  float: left;
}

.tableConfigResults td {
  padding: 0px;
}

.tableConfigResults th {
  width: 2%;
  color: #d8d8d8;
  font-size: 1px;
}