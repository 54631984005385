.profile-box {
  Width: 100%;
  padding-top: 40px;
  height: fit-content;
  position: absolute;
  padding-bottom: 70px !important;
}

.profile-section {
  margin: auto;
  width: 98%;
  height: fit-content;
}

.profile-section .edit-icon-btn {
  margin-top: -5px !important;
  margin-right: -5px !important;
  float: right;
}



.profile-section-profile-image,
.profile-section-profile-information {
  float: left;
  margin-right: 1%;
  width: 33%;
  margin-bottom: 10px;
  height: 380px;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.profile-section-profile-notifications {
  float: left;
  width: 66%;
  margin-bottom: 10px;
  height: 380px;
  border-radius: 4px;
}

.profile-section-profile-account-access {
  float: left;
  margin-bottom: 10px;
  width: 100%;
  padding-bottom: 10px;
  height: fit-content;
}

.profile-section .hr-line {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 0px !important;
}

.profile-section-profile-image .card-image-userprofile {
  width: 82%;
  margin: auto;
}

.account-access-btn {
  margin-right: 10px !important;
  background-color: #435cd2 !important;
}

.profile-section-profile-image .card-image-userprofile img {
  border-radius: 100%;
  width: 250px;
  height: 250px;
}


.profile-section-profile-image-small-wrap {
  float: left;
  z-index: 9 !important;
  margin-top: -63px;
}

.profile-section-profile-image-small {
  margin-left: 178px;
  z-index: 9;
  height: 60px !important;
  width: 60px !important;
  color: #435cd2;
  padding: 15px;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.profile-section-profile-image-small:hover {
  background-color: #435cd2;
  color: white;
  cursor: pointer;
}

.profile-section-profile-image-big {
  height: 250px;
  width: 250px;
  z-index: -99;
  margin-top: 20px;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.profile-section-profile-image-company-big {
  height: 150px;
  width: 150px;
  z-index: -99;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.profile-section-profile-image-company-small {
  margin-left: 108px;
  z-index: 9;
  margin-top: 20px;
  height: 40px !important;
  width: 40px !important;
  color: #435cd2;
  padding: 6px;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.profile-section-profile-image-company-small:hover {
  background-color: #435cd2;
  color: white;
  cursor: pointer;
}

.form-image-wrap {
  width: 100%;
}

.form-image-search-btn-wrap,
.form-image-search-text-wrap {
  float: left;
}

.form-image-search-text-wrap {
  width: 81%;
}


.form-image-search-btn-wrap {
  margin-top: 8px;
}

.edit-user-btns-wrap-one {
  float: left;
  text-align: left;
  width: 50%;
}

.edit-user-btns-wrap-two {
  float: left;
  text-align: right;
  width: 50%;
}

.search-image-btn {
  padding: 7px !important;
}

.form-add-btn-img {
  float: left;
}

.preview-image {
  width: 300px;
  height: 300px;
  margin: auto;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.profile-image-edit-header-line {
  border-top: 1px solid lightgray;
  width: 100%;
}

.form-add-btn-img {
  float: right;
  margin-bottom: 10px !important;
  margin-right: 20px !important;
}


.form-image-btn {
  Width: 100%;
}

.form-submit-btn {
  Width: 100%;
  float: left;
  text-align: right;
}

.form-upload-image {
  width: 50%;
  margin: auto;
  height: fit-content;
  padding-top: 20px;
}

.save-account {
  margin-top: 25px !important;
  margin-bottom: 10px !important;
}

.card-content-info {
  padding-top: 5px;
  width: 100%;
  height: fit-content;
}

.card-content-info b {
  text-shadow: 1px 0px 1px rgb(179, 179, 179);
}

@media screen and (max-width:920px) {

  .profile-section-profile-image,
  .profile-section-profile-information,
  .profile-section-profile-notifications {
    width: 100% !important;
    height: fit-content;
  }

}