.ConnectWrap {
  width: 98%;
  height: fit-content;
  margin: auto;
}

.tableConnectResults td {
  padding: 0px;
}

.tableConnectResults th {
  width: 2%;
  color: #d8d8d8;
  font-size: 1px;
}

.paperSearchBox {
  padding: 4px !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  box-shadow: none !important;
  border: 1px solid lightgray !important;
}

.searchBoxConnectWrap {
  width: 100%;
  margin-top: 90px !important;
  height: fit-content;
}

.drawerBox {
  width: 350px;
}

.connectionIcons {
  padding-left: 25px;
}