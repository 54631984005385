/* ===== Styling That Cant Be Used As Overwrite ===== */
@import url('https://fonts.googleapis.com/css?family=Montserrat');
/** NEW UI **/

:root {
  --primaryActive: #4B69F9;
  --primaryHover: #6F87FA;
  --white: #ffffff;
  --secondary: #101820;
  --background: #F0F0F1;
  --notificationFailed: #D81B60;
  --notificationValid: #3DBB95;
  --notificationWarning: #FF6F00;
  --primaryGrey: #4E545B;
  --secondaryGrey: #C8CBCC;
  --lightGrey: #e2e3e4;
  --fontFamily: 'Montserrat';
}

/* ===== Grid Type label ===== */
.grid-labels {
  font-weight: 900;
  height: 24px;
  margin-bottom: 15px;
}


/* ===== Grid Type label Icon ===== */
.grid-label-icon-question-mark {
  font-size: 14px !important;
  margin-left: 10px;
  color: #435cd2;
}


.profile-view-labels {
  height: fit-content;
  font: 700 26px var(--fontFamily);
  margin-bottom: 14px;
  margin-top: 14px;
  letter-spacing: 0px;
}

.navigation-icon {
  font-size: 35px !important;
  float: right;
  cursor: pointer;

}

#navigation-logout {
  width: 3%;
  float: left;
}

.text-label-box {
  font-weight: 800;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 15px;
}

.text-label-astrid {
  color: var(--notificationFailed);
  margin-right: 10px;
}

.control-label-checkbox {
  margin-top: 5px;
}


.box-label-profile-step {
  background-color: var(--primaryActive);
  color: var(--white);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px;
  width: 60%;
  text-align: center;
}

.box-label-profile-button {
  color: var(--primaryActive);
  padding: 10px;
  border: 1px solid var(--lightGrey);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 60%;
  margin-bottom: 10px;
}

.box-label-profile-stack {
  width: 140px;
  margin: auto;
}

.icon-for-text-valid {
  color: var(--notificationValid);
}

.icon-for-text-failed {
  color: var(--notificationFailed);
}

#Documents-Sub-Heading {
  margin-bottom: 20px;
  margin-top: 20px;
}

.typ-Bold {
  font-weight: 800 !important;
}

.text-search-border-bottom {
  border-bottom: 2px solid var(--primaryActive) !important;
}

.primary-color {
  color: var(--primaryActive);
}

.text-area {
  width: 95%;
  height: 300px;
  resize: none;
  border-radius: 15px;
  border-color: #C8CBCC;
  padding: 10px;
}


.heading-title-products {
  font-size: 40px !important;
}

.header-sub-products {
  background-color: var(--primaryActive);
  color: var(--white) !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 98%;
  text-align: center;
  border-radius: 12px;
  margin-bottom: 10px;
}

.hr-multi {
  width: 110%;
  border-top: 1px solid gray;
  height: 2px;

}

.color-grey {
  color: var(--lightGrey);
}

.color-primary {
  color: var(--primaryActive);
}

.dialog-box .MuiDialog-paper {
  max-width: 950px !important;
  height: fit-content;
}

.dialog-wrap-content-box {
  width: 650px;
  height: fit-content;
  padding: 20px;
}

.margining-10 {
  margin-left: 10px;
}


.base-page-heading-box {
  margin-left: 20px;
  margin-bottom: 20px;
  width: 98%;
  height: fit-content;
}

.base-page-heading-card {
  padding: 20px;
}

.base-page-chat-card {
  margin-top: 20px;
}