.sidebar__statNumber_workcenter {
  font-weight: bold;
  color: #0a66c2 !important;
}

.workcenter-list-box {
  width: 28%;
  height: fit-content;
  margin-left: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.workcenter-dashboard-heading {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
}

.workcenter-frame {
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
}

.heading-box {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.table-heading th {
  color: white;
  background-color: #0a66c2;
  font-weight: 900 !important;
}