.mydataHeaderBox {
  Width: 70%;
  margin-left: 15%;
  float: left;
  margin-top: 50px;
  height: fit-content;
}

.mydataHeaderBox .cardContent {
  width: 100%;
}

.card {
  height: auto;
  width: 100%;
  background: #fff;
  border-radius: 7px;
  padding: 15px 18px;
  display: flex;
  column-gap: 5%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.tableSearch {
  width: 100%;
}

.searchTearmCell {
  width: 30%;
}

.categoryCell {
  width: 20%;
}

.locationCell {
  width: 20%;
}

.areaCell {
  width: 10%;
}

.dateCell {
  width: 10%;
}

.actionCell {
  width: 10%;
}