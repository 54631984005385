@import url('https://fonts.googleapis.com/css?family=Montserrat');

:root {
  --primaryActive: #4B69F9;
  --primaryHover: #6F87FA;
  --white: #ffffff;
  --secondary: #101820;
  --background: #F0F0F1;
  --notificationFailed: #D81B60;
  --notificationValid: #3DBB95;
  --notificationWarning: #FF6F00;
  --primaryGrey: #4E545B;
  --secondaryGrey: #C8CBCC;
  --lightGrey: #e2e3e4;
  --fontFamily: 'Montserrat';
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.red-text {
  color: var(--notificationFailed);
}

.highlightRed {
  background-color: #dd2727 !important;
}

.highlightOrange {
  background-color: #ff8800 !important;
}

.highlightGreen {
  background-color: #22834e !important;
}


.drawer-temp .MuiDrawer-paper {
  width: 350px !important;
}

.btn-save-box {
  width: 100%;
  height: fit-content;
}

.textField982 {
  width: 98%;
  margin-top: 0px !important;
  margin-right: 2% !important;
}

.basic-textField {
  width: 100%;
}

.body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 30px;
}



.iconbtn {
  background-color: #f5f4f4 !important;
  cursor: default !important;
  border-radius: 0% !important;
}

.tour-btn:hover {
  cursor: pointer;
}



.cl-green {
  color: #008000 !important;

}

.cl-green-avatar {
  background-color: #008000 !important;

}

.cl-red {
  color: #a01616;

}

.cl-gray {
  color: #808080;

}

.cl-lightGray {
  color: rgb(161, 159, 159);
}

.cl-blue {
  color: #435cd2;
}

.chip-colored {
  background-color: #435cd2 !important;
  color: white !important;
  box-shadow: 0px 1px 1px black;
}

.cl-yellow {
  color: #ffcc00;
}

.cl-orange {
  color: #e68a00;
}

.cl-white {
  color: rgb(19, 29, 119);

}

.cl-white:hover {
  color: white;
}


.gray-icon {
  color: lightgray !important;
}

/*Search Main*/

.search-Paper {
  width: 100%;
}



.search-advanced-btn-wrap {
  width: 100%;
  padding: 20px;
  text-align: right;
  height: fit-content;
}

.platformConfigurationBox {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  height: fit-content;
  padding-bottom: 10px;
  margin-bottom: 20px;
  width: 97.4%;
  margin-left: 25px;
}

.menuitemsearch {
  background-color: white !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.search-split-menu {
  margin: 10px !important;
}


.result-of-search {
  height: fit-content;
  width: 100%
}


.text-main-search-box {
  Width: 100%;
  background-color: white;
  float: left;

}

.text-main-search {
  border: none !important;
  padding-left: 10px;
  padding-top: 7px;
  width: calc(100% - 50px);
}

.switch-to-advanced-btn {
  border-radius: 0px !important;
}

.link-green {
  width: 30px;
  height: 30px;
  margin-top: -3px;
  padding-top: 3px;
  border-radius: 100%;
  text-align: center;
  color: green;
  background-color: rgba(204, 224, 204, 0.315);
}


.icons-search {
  color: rgb(199, 197, 197);
  font-size: 17px !important;
  margin-top: 3px !important;
}


.list-grid-text {
  font-size: 0.9em !important;
  white-space: normal;
  word-break: break-word;
}




.lines-colour-every {
  width: 100%;
  height: fit-content;
}

.lines-colour-every>.MuiPaper-root:nth-child(even) {
  background-color: rgb(245, 250, 255) !important;
}



.list-grid-text a {
  text-decoration: none;
  color: #435cd2;
}

.list-system-components-text {
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.8);
}

.list-system-components-text:hover {
  color: #435cd2;
}

/*Dashboard widget headings*/
.dashboard-grid-container .MuiTypography-root {
  font-weight: 900 !important;
  font-size: 1.3rem !important;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
}

.dashboard-grid-container h2 {
  font-weight: 900 !important;
  font-size: 1.3rem !important;
  color: rgba(0, 0, 0, 0.8);
}

.heading-large {
  font-size: 1.5rem;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.8);
}



.heading-sub {
  font-size: 1.3rem;
  font-weight: 800 !important;
  color: rgba(0, 0, 0, 0.8);
}

.heading-sub-3 {
  font-size: 1.1rem;
  font-weight: 800 !important;
  color: rgba(0, 0, 0, 0.8);
}

.heading-sub-4 {
  font-size: 1rem;
  font-weight: 800 !important;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid gainsboro;
}

.heading-sub-5 {
  font-size: 1rem;
  font-weight: 800 !important;
  color: rgba(68, 66, 66, 0.8);
}

.heading-sub-6 {
  font-size: 1.1rem;
  font-weight: 800 !important;
  color: #435cd2;
}

.wrap-no-padding {
  height: fit-content;
  width: 100%;
  margin-top: 70px;
}

.hr-line {
  border-top: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.hr-line-dashed {
  border-top: 1px dashed lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}



.small-heading {
  font-size: 12px;
}

.section-wrap {
  padding: 20px;
  width: 100%;
  height: fit-content;
}


.disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
}

.small-note {
  font-size: 11px;
  font-weight: 500;
}

.app-bar,
.app-bar-sub {
  background-color: #ffffff !important;
  box-shadow: none !important;
  color: black !important;
  padding: 10px;
}

.MuiTabs-indicator {
  background-color: #435cd2 !important;
}




.close-btn-suggestions {

  margin-top: 5px;
  margin-right: 5px;
  font-size: 20px !important;
  color: rgba(0, 0, 0, 0.54);
}

.close-btn-suggestions:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.close-btn-search {

  margin-right: -10px !important;
  font-size: 30px !important;
  color: rgba(0, 0, 0, 0.54);
}

.close-btn-search:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  color: #435cd2;
  border-radius: 4px;
}

.show {
  display: block;
  width: 3%;
  float: right;
}

.save-btns {
  float: right;
  background: #435cd2 !important;
}

.modal-btn-save {
  background: #435cd2 !important;
}

.modal-btn-save:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) !important;
}

.modal-btn-discard {
  background-color: #b82828 !important;
}

.modal-btn-discard:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) !important;
}

.modal-btn-cancel {
  background-color: #6e7881 !important;
}

.modal-btn-cancel:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) !important;
}


/*Platform Configuration*/
.config-header-box {
  Width: 100%;
  Margin: auto;
  height: fit-content;
}

.config-sub-box {
  width: 100%;
  height: fit-content;
}



.config-sub-box-left {
  width: 250px;
  margin-right: 1%;
  height: fit-content;
  padding-right: 10px;
  border-radius: 4px;
  float: left;
}

.stepButton {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}

.stepButton .MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.stepButton .Mui-active {
  color: #435cd2 !important;
}


.stepButton2 .MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.stepButton2 .Mui-active {
  color: #435cd2 !important;
  font-weight: 700 !important;
}



.config-sub-box-right {
  width: calc(99% - 250px);
  height: fit-content;
  float: left;
}


.app-bar-tabs {
  align-items: unset !important;
  text-align: left !important;
  -webkit-box-align: unset !important;
}



/*Page Scroll Bar*/
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #435cd2;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #435cd2;
}

::-webkit-scrollbar-thumb:active {
  background: #435cd2;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
  background: #ffffff;
}

::-webkit-scrollbar-track:active {
  background: #ffffff;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.app-bar-wrap {
  width: 95%;
  margin-top: 10px;
}

.app-bar-tabs {
  align-items: unset !important;
  text-align: left !important;
  -webkit-box-align: unset !important;
}

.paper-search-box {
  padding: 4px !important;
  margin-top: 20px !important;
}

.inactive-set {
  display: none;
}

.show-set {
  display: inline-block;
  width: 9%;
  float: right;
}


/*Agent Validation*/
.company-validation-entire-box {
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
}

.btn-validate {
  float: right;
  margin-top: 10px !important;
}

.custom-header {
  padding-right: 10px;
}


.highlighted:hover {
  cursor: pointer;
}



.icons-header-table {
  font-size: 14px !important;
}


/* Global Over-write no outline on row fields */
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: 0 !important;
}

/* Over-write need to set width dynamically */
.dialog-container .MuiDialog-paper {
  max-width: unset !important;
  width: 750px;
  Height: 800px;
}

/* Global Over-write include Company Brand Colours on Each Secound row */
.MuiDataGrid-root .highlighted:nth-child(even) {
  background-color: rgb(245, 250, 255) !important;
}

.MuiDataGrid-root .highlighted:nth-child(even):hover {
  background-color: rgba(224, 224, 224, 0.33) !important;
}

/* Global Over-write include Company Brand Colours */
.MuiDataGrid-columnHeaders {
  background-color: #435cd2 !important;
  color: #ffffff !important;
  font-weight: 900 !important;
}

.Accord-Hide {
  box-shadow: none !important;
}

.Accord-heading {
  background-color: #435cd2 !important;
}

.Accord-heading h3 {
  text-shadow: none !important;
  color: #ffffff;
}

.expand-icon-white {
  color: #ffffff;
}

.advanced-filter-box {
  width: 100%;
  height: 200px;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  overflow-y: scroll;
  padding: 15px;
}

.gridclass .MuiSvgIcon-root {
  color: #435cd2;
}

.MuiSvgIcon-root[data-testid="TripleDotsVerticalIcon"] {
  color: #ffffff !important;
}

/*Overlay*/
.MyLoader_overlay {
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: "rgba(0, 0, 0, 0.5)",
}

.MyLoader_content span span {
  background-color: #435bd2be !important;
}

.small-blinkie {
  height: 150px;
  width: 150px;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  text-align: center;
}

.popoverDetails .MuiPaper-root {
  box-shadow: none !important;
  border: 1px solid gainsboro;
  border-radius: 0%;
}


.connect .MuiTabs-flexContainer {
  background-color: #435cd2;
}

.connect .Mui-selected {
  background-color: white;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  color: black !important;
}

.connect .MuiTab-root {
  color: #ffffff;
  font-weight: 600;
}

.connect .MuiTabs-indicator {
  bottom: unset !important;
  top: 0;
}

.gridclass .MuiDataGrid-cell {
  white-space: unset !important;
  vertical-align: top !important;
}

.align-top-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.row-bottom-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 300px;
  background-color: #f0f0f0;
  /* Add any other styling properties as per your requirements */
}





.green-cell {
  color: #f0f0f0;
  background-color: green !important;
}

.red-cell {
  color: #ffffff;
  background-color: #dd6210 !important;
}

.orange-cell {
  color: #ffffff;
  background-color: #b82828 !important;
}


.deleteValidationBtn:hover {
  color: #a01616;
}

.editableValidationBtn:hover {
  color: #435cd2;
}

.testValidationBtn:hover {
  color: #435cd2;
}



.myConnectionsDashboardTable {
  max-width: 110% !important;
}

.notificationBadge span {
  background-color: #008000;
  color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.iconVerified {
  color: #008000;
}


icons-dmpr:hover {
  color: #a01616 !important;
}

.complianceBox {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}


.activeRow {
  background-color: gold;
}

.default-btn-switch:hover {
  color: gold;
}

.default-btn-switchCompany {
  margin-right: 10px;
}

.default-btn-switchCompany:hover {
  color: #435cd2;
}