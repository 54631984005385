.sidebar__top_home>h2 {
  font-size: 14px;
}

.sidebar__top_home>h4 {
  color: gray;
  font-size: 12px;
}

.sidebar__top_home {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  padding-bottom: 10px;
}

.sidebar__top_home>img {
  width: 100%;
  height: 60px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}


.sidebar__stat {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.sidebar__stat>p {
  color: gray;
  font-size: 13px;
  font-weight: 600;
}

.sidebar__stats_home {
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.sidebar__statNumber {
  font-weight: bold;
  color: #0a66c2 !important;
}

.sidebar__bottom_home {
  text-align: left;
  padding: 10px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar__recentItem {
  display: flex;
  font-size: 13px;
  color: gray;
  cursor: pointer;
  font-weight: bolder;
  margin-bottom: 5px;
  padding: 5px;
}

.sidebar__bottom>p {
  font-size: 13px;
  padding-bottom: 10px;
}

.sidebar__bottom>h4 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  color: #0a66c2;
}

.sidebar__recentItem:hover {
  background-color: whitesmoke;
  border-radius: 10px;
  cursor: pointer;
  color: black;
}

.sidebar__hash {
  margin-right: 10px;
  margin-left: 5px;
}


.home {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: fit-content;
  padding-top: 20px;
  padding-bottom: 10px;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
}

.icon-tour-btn {
  width: 90px;

}

.icon-tour-btn:hover {
  width: 90px;
  background: none !important;
}

.home>img {
  width: 100%;
  height: 60px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.profile-image-company-home {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background: #435cd2;
}


.responsive-banner {
  display: inline-block;
  width: 100%;
  min-width: 120px;
  position: relative;
  height: 180px;
  border-radius: 4px;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #589FE5, #435cd2);
  background-repeat: no-repeat;
  text-align: left;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}


.container-envelope {
  max-width: calc(100%);
  padding: 15px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 180px;
  max-height: 180px;
}

.col-xs-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@media (min-width: 650px) {
  .responsive-banner .col-xs-12 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .container-envelope {
    max-width: calc(100% - 200px);
  }
}

.responsive-banner p {
  clear: both;
  text-transform: none;
  text-rendering: optimizeLegibility;
  font-weight: 500;
  line-height: 1.15;
  word-wrap: break-word;
  margin: 1em 0 0.5em;
  margin: 0;
  padding: 0;
  color: #fff;
  position: relative;
  word-wrap: break-word;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}


.responsive-banner a.more-link {
  background-color: rgba(255, 255, 255, 0.35);
  display: inline-block;
  padding: 12px 18px;
  color: #fff;
  text-decoration: none;
  font: 500 15px 'Work Sans', Helvetica, sans-serif;
  line-height: 1.5;
  text-align: center;
  border: none !important;
  position: relative;
  border-radius: 30px;
  text-transform: uppercase;
  -webkit-transition: .1s all ease-in-out;
  -moz-transition: .1s all ease-in-out;
  -o-transition: .1s all ease-in-out;
  transition: .1s all ease-in-out;
}

.responsive-banner a.more-link:hover {
  background-color: rgba(255, 255, 255, 0.50);
}

.responsive-banner svg,
.responsive-banner img {
  fill: rgba(0, 0, 0, 0.1);
  position: absolute;
}

.responsive-banner img {
  max-width: 50%;
}

.cirle-a {
  bottom: -80px;
  left: -80px;
}

.cirle-b {
  top: -30px;
  right: 70%;
}

.cirle-c {
  top: -330px;
  right: -300px;
}

.cirle-d {
  top: 195px;
  right: 145px;
}

.responsive-banner img {
  top: 0;
  right: 0;
}


.link-container {
  text-align: center;
}

.link-container a.more-link {
  background-color: #90b0bf;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 1.5;
  text-decoration: none;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 1px;
}

.table-header {
  background-color: #435cd2;

}

.table-header th {
  color: white !important;
}