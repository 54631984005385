.profile-image-company {
    height: 130px;
    width: 130px;
    margin-left: -25px;
    border-radius: 100%;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background: #435cd2;
}

.disabled {
    pointer-events: none;
    color: #AAA;
    background: #F5F5F5;
}

.sidebar-company-profile {
    position: fixed;
    width: 20%;
    margin-bottom: 20px;
}

.sidebar-company-profile-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    padding-bottom: 10px;
}

.sidebar-company-profile-top h3 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}