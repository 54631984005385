.table {
  width: 100%;
  margin-bottom: 10px;
}

.table th {

  background-color: #435cd2;
  color: white;
  padding: 10px;
}

.table td {
  text-align: center;
}