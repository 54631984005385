.searchBoxWrap {
  width: 90%;
  margin: auto;
  height: fit-content;
  padding-top: 20px;
}

.resultWrap {
  width: 100%;
}

.resultLeft {
  float: left;
  width: 29%;
  margin-right: 1%;
  height: fit-content;
  padding-bottom: 50px;
}

.resultRight {
  float: left;
  width: 70%;
  height: fit-content;
  padding-bottom: 50px;
}

.sideListTopHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  padding-left: 10px;
  width: 95%;
  margin: auto;
}

.sideListSecoundHeader {
  border-top: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  padding-left: 10px;
  width: 95%;
  margin: auto;
}

.sideListTop {
  height: 500px;
  width: 95%;
  margin-left: 2.5%;
  overflow-y: scroll;
}

.accordnetconnectlist {
  height: 650px;
  width: 95%;
  margin-left: 2.5%;
  padding: 0px !important;
}


.listSystemComponentsIcons {
  font-size: 4em !important;
  color: lightgray;
  margin-top: 10px !important;
}

.listSystemComponentsText {
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.8);
}

.listSystemComponentsText:hover {
  color: #435cd2;
}


.boxCol {
  width: 25%;
  height: 200px;
  float: left;
}

.tableFull {
  width: 100%;
}


.tableFull {
  border-collapse: collapse;
  width: 100%;
}

.tableFull td,
.tableFull th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableFull tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableFull tr:hover {
  background-color: #ddd;
}

.tableFull th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #435cd2;
  color: white;
}

.searchDetailsWrap {
  width: 100%;
}

.searchDetailsWrapBox {
  width: 100%;
  height: 200px;
}


.connectionTools {
  height: 40px;
  width: 100%;
}

.companyDetailsHeader {
  width: 100%;
  padding: 10px;
  margin-top: 60px;
  color: white;
  background-color: #435cd2;
}

.companyServiceHeader {
  width: 100%;
  padding: 10px;
  background-color: #435cd2;
  color: rgb(255, 255, 255);
}

.companyaddressHeader {
  width: 100%;
  color: white;
  height: fit-content;
}

.companyaddressHeaderRight,
.companyaddressHeaderLeft {
  width: 50%;
  padding: 10px;
  background-color: #435cd2;
  float: left;
}

.companyViewLeft {
  height: 90px;
  padding-top: 40px;
  color: white;
  float: left;
}

.companyViewRight {
  height: 90px;
  width: 200px;
  float: left;
}

.companyProfileImage {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-left: 20px;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}



/*new*/
.box {
  width: 100%;
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.companyViewBox {
  height: 130px;
  width: 100%;
  padding: 10px;
  background-color: #435cd2;
}

.companyDetailsHeader {
  width: 100%;
  padding: 10px;
  margin-top: 60px;
  color: white;
  background-color: #435cd2;
}

.companyServiceHeader {
  width: 100%;
  padding: 10px;
  background-color: #435cd2;
  color: rgb(255, 255, 255);
}

.companyaddressHeader {
  width: 100%;
  color: white;
  height: fit-content;
}

.companyaddressHeaderRight,
.companyaddressHeaderLeft {
  width: 50%;
  padding: 10px;
  background-color: #435cd2;
  float: left;
}

.companyViewLeft {
  height: 90px;
  padding-top: 40px;
  color: white;
  float: left;
}

.companyViewRight {
  height: 90px;
  width: 200px;
  float: left;
}



.sectionDetails {
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.sectionDetailsJoined {
  width: 100%;
  min-height: 170px;
  display: flex;
}

.sectionDetailsLeft,
.sectionDetailsRight {
  width: 50%;
  padding: 10px;
  float: left;
}

.sectionDetailsFirstLeft {
  width: 80%;
  padding: 10px;
  float: left;
}

.sectionDetailsFirstRight {
  width: 20%;
  padding: 10px;
  float: left;
}

.sectionDetailsPrint {
  display: none;
}