#top-nav {
  background-color: #4b69f9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  padding: 10px;
}

#top-nav #logo {
  height: 50px;
  margin-left: 12%;
}

#top-nav nav a {
  color: white;
  text-decoration: none;
  border: 1px solid White;
  margin-right: 10px;
  padding: 5px;
}

#top-nav nav {
  padding-top: 10px;
  width: 65%;
}

.nav-div {
  width: 100%;
  height: 30px;
  text-align: right;
}