.icon-box {
    width: 50px;
    float: left;
    margin: auto;
}

.btn-add {
    padding: 0px;
}

.add-list-icons {
    margin-left: -4px !important;
}

.active {
    display: block;
    width: 100%;
}

.not-active {
    display: none;
    width: 100%;
}

.form-add-btn {
    width: 100px;
    height: 40px;
}

.add-panel-box,
.add-role-panel-box,
.edit-panel-box {
    padding: 10px;
}

.add-panel-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 50px;
    margin-bottom: 50px;
}

.add-panel-image-icon {
    font-size: 150px !important;
    color: #435cd2;
}

.add-panel-heading {
    color: #3f3f42;
    text-align: center;
    margin-bottom: 10px;
}

.add-panel-footer {
    position: static;
    bottom: 0;
    width: 340px;
    padding-bottom: 10px;
}

.add-panel-close-box {
    margin-top: 5px;
    margin-left: 5px;
}

.add-panel-add-roles-btn-box {
    float: left;
    width: 40%;
}

.add-panel-save-btn-box {
    float: left;
    width: 50%;
}

.add-panel-add-roles-btn {
    width: 100%;
    border-radius: 0% !important;
}

.add-panel-role-select {
    width: 95% !important;
}

.role-select-add-role-container {
    width: 100%;
}

.role-add-btn-box {
    width: 15%;
    margin-top: 7px;
    float: left;
}

.edit-panel-close-box {
    margin-top: 5px;
}

.edit-roles-edit-panel-box {
    width: 100%;
}

.edit-roles-panel-text {
    width: 78%;
    margin-right: 2%;
    float: left;
}

.edit-roles-panel-btn {
    width: 20%;
    margin-top: 8px;
    float: left;
}

.form-edit-btn {
    height: 39.5px;
}

#demo-multiple-checkbox-dash-label b {
    width: 100%;
    height: 20px;
    padding-top: -10px;
    margin-top: -10px !important;
}

.icon-use-edit {
    margin-top: -10px;
}

.valid {
    border-color: green;
}

.not-valid {
    border-color: red;
}