.chatBoxList {
  padding: 10px;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  flex-direction: column-reverse;
  display: flex;
}

.chatBox {
  padding: 10px;
}

.chatBoxParent {
  height: 55px;
  width: 99%;
  margin-left: 10px;
  align-self: flex-end;
}

.chatTop {
  height: 80px;
  width: 100%;
  padding-top: 25px;
  padding-left: 20px;
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  top: 0;
  align-self: flex-start;
}

.chatBoxParent .container-input {
  background-color: transparent !important;
  margin-top: 0px !important;
}