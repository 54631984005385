@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: url("./fonts/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat,";
  font-weight: 800;
  src: url("./fonts/Montserrat-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat,";
  font-weight: 800;
  src: url("./fonts/Montserrat-SemiBold.ttf") format('truetype');
}

/** NEW UI **/

:root {
  --primaryActive: #4B69F9;
  --primaryHover: #6F87FA;
  --white: #ffffff;
  --secondary: #101820;
  --background: #F0F0F1;
  --notificationFailed: #D81B60;
  --notificationValid: #3DBB95;
  --notificationWarning: #FF6F00;
  --primaryGrey: #4E545B;
  --secondaryGrey: #C8CBCC;
  --lightGrey: #e2e3e4;
  --fontFamily: 'Montserrat';
}

.btn-black {
  background-color: var(--secondary) !important;
}

.icon-primaryActive {
  color: var(--primaryActive);
}

.largeHeading {
  font-weight: 900;
  font-family: var(--fontFamily);
  font-size: 36px;
  margin-bottom: 20px;
}

.pageTitle {
  font-weight: 600;
  font-family: var(--fontFamily);
  font-size: 32px;
}

.subHeading {
  font-weight: 300;
  font-family: var(--fontFamily);
  font-size: 24px;
}

.bodyCopy {
  font-family: var(--fontFamily);
  font-size: 16px;
}

.labelStandard {
  font-family: var(--fontFamily);
  font-size: 15px;
}

.link {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

.labelSmall {
  font-family: var(--fontFamily);
  font-size: 13px;
}

.pageBox {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 70px;
}

/**Buttons**/


/**Dashboard specific components**/

.companyLogo {
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(to bottom right, #589FE5, #435cd2);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.companyName {
  margin-top: 60px;
}

.cardMainProfile {
  background-color: var(--white);
  width: 100%;
  margin-top: 48px;
  height: fit-content;
  border-radius: 15px !important;
}

.cardHeader {
  background-color: var(--primaryActive);
  color: var(--white);
  margin-right: -20px;
  margin-top: -20px;
  font-weight: 800;
  margin-left: -20px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
}

.cardHeader-compliance {
  background-color: var(--primaryActive);
  color: var(--white);
  margin-right: -20px;
  padding-top: 10px;
  font-weight: 800;
  margin-left: -20px;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
}

.table-header-card {
  background-color: var(--primaryActive);
  color: var(--white);
}

.cardBanner {
  background-color: var(--secondaryGrey) !important;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.cardSearch {
  margin-bottom: 20px;
  margin-top: 10px;
}


body {
  background-color: var(--background);
  font-family: var(--fontFamily);
}

.tabs-dashboard .MuiTab-root {
  background-color: var(--secondary) !important;
  color: var(--white) !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  margin-right: 5px !important;
  font-weight: 600 !important;
}

.tabs-dashboard .MuiTab-root:hover {
  background-color: var(--primaryHover) !important;
  color: var(--white) !important;
}

.drop-filter .Mui-selected {
  background-color: var(--primaryActive) !important;
  color: var(--white) !important;
}

.tabs-dashboard .Mui-selected {
  background-color: var(--primaryActive) !important;
  color: var(--white) !important;
}

.notificationCard {
  width: calc(50% - 30px);
  height: 90px;
  float: left;
  margin: 15px;
  border-radius: 15px;
  border: 2px solid var(--lightGrey);
}

.notificationCardSmall {
  width: 98%;
  height: 90px;
  float: left;
  margin: 5px;
  border-radius: 15px;
  border: 2px solid var(--lightGrey);
}

.startOfCard {
  width: 20px;
  height: 100px;
  margin-top: -2px;
  margin-left: -2px;
  background-color: var(--primaryActive);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

}

.middleOfCard {
  width: calc(100% - 45px - 20px);
  padding: 10px;
  height: 20px;
  font-family: var(--fontFamily);
  font-size: 14px;
}

.cardInside {
  margin-bottom: 5px;
}

.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Change the number to the desired number of lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 100%;
}


.cardInsideRight {
  font-size: 12px;
  padding-top: 2px;
  color: var(--primaryGrey);
}

.endOfCard {
  width: 45px;
  padding-top: 5px;
}

.notification_title {
  color: #9c9c9c;
  font-size: 13px;
  padding-bottom: 5px;
}

.icon_btn_color_current {
  color: var(--primaryActive);
}


.sideBlockInsight {
  background-color: #f0f0f1;
  height: fit-content;
  border-radius: 4px;
  padding: 20px;
  margin-right: 30px;
  margin-bottom: 40px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.sideBlockInsightTop {

  margin-top: 100px;
  color: var(--primaryActive);
}

.sideBlockInsight h1 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.chipRed {
  background-color: var(--notificationFailed) !important;
  font-size: 800 !important;
  border-radius: 8px !important;
  color: var(--white) !important;
}

.chipGreen {
  background-color: var(--notificationValid) !important;
  font-size: 800 !important;
  border-radius: 8px !important;
  color: var(--white) !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #4b69f9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #4b69f9;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.table-box {
  width: 98%;
}

.table-box-mp {
  width: 100%;
}

.table-box-my-connection-filters {
  width: 100%;
}

.hr-container {
  border-top: 2px solid var(--primaryActive);
  margin-bottom: 20px;
}

.hr-container-products {
  border-top: 2px solid var(--primaryActive);
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.hr-container-later {
  border-top: 2px solid var(--primaryActive);
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.textfield-rounded {
  border-radius: 100px !important;
  border: 1px solid var(--secondary);
  width: 70px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-top: -5px;
  background-color: var(--white);
}

.select-no-border {
  color: var(--primaryActive) !important;
}


.btn-custom-table-actions {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--white);
  color: var(--primaryActive);
  padding: 15px;
}

.btn-custom-table-actions:hover {
  background-color: var(--primaryActive);
  color: var(--white);
}


.btn-custom-table-actions-adv {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--white);
  color: var(--primaryActive);
  padding: 15px;
  min-width: 160px;
  float: right;
}

.btn-custom-table-actions-adv-clear {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
  background-color: var(--secondary);
  background-size: 200% 100% !important;
  color: var(--white);
  padding: 17px;
  min-width: 160px;
  float: right;
  margin-right: 30px;
  background-position: right bottom !important;
  transition: all ease .6s !important;
}

/* ===== Grid Type label Css ===== */
.adv-search-drop-labels {
  font-weight: 900;
  height: 24px;
  margin-bottom: 15px;
}

.btn-custom-table-actions-adv-search {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--white);
  color: var(--primaryActive);
  padding: 15px;
  width: 200px;
  margin-left: 20px;
}


.btn-custom-table-actions-product {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: 2px solid var(--primaryActive);
  border-left: 2px solid var(--primaryActive);
  border-right: 2px solid var(--primaryActive);
  background-color: var(--white);
  color: var(--primaryActive);
  padding: 15px;
  width: 200px;
}

.btn-custom-table-actions-logout {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--primaryActive);
  color: var(--white);
  padding: 15px;
  margin-left: 10px;
  width: 250px;
}

.btn-custom-table-actions-setup {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--white);
  padding: 15px;
  float: right;
  margin-right: 10px;
  width: 250px;
}

.btn-custom-table-actions-adv-product {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--primaryActive);
  color: var(--white);
  padding: 15px;
  min-width: 160px;
  float: right;
}

.icon-adv-help {
  font-size: 14px !important;
  margin-left: 10px;
  color: #435cd2;
}

.grid-spacing-adv {
  margin-bottom: 30px !important;
  margin-top: 10px !important;
  padding-left: 20px;
}

.table-box-adv {
  width: 100%;
}

/* Custom buttons */
.btn-custom-table-actions-adv:hover {
  background-color: var(--primaryHover);
  color: var(--white);
}

.btn-custom-table-actions-adv-clear:hover {
  background-color: #101820da;
  color: var(--white);
}

.btn-custom-table-actions-adv-search:hover {
  background-color: var(--primaryActive);
  color: var(--white);
}

.btn-custom-table-actions-request {
  background-color: var(--primaryActive);
  color: var(--white);
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  padding: 16px;
  width: 200px;
  margin-right: 20px;
  float: right;
}

.btn-custom-table-top-actions {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 2px solid var(--primaryActive);
  border-bottom: 2px solid var(--primaryActive);
  background-color: var(--white);
  color: var(--primaryActive);
  padding: 16px;
  cursor: pointer;
}

.btn-custom-table-top-actions:hover {
  background-color: var(--primaryActive);
  color: var(--white);
}


.btn-custom-table-simple {
  border-top-left-radius: 0;
  width: 160px;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--primaryActive);
  color: var(--white);
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 15px;
}

.btn-custom-table-simple:hover {
  background-color: var(--primaryHover);
  border-color: var(--primaryHover);
  color: var(--white);
}

.btn-custom-table-top-simple:hover {
  background-color: var(--primaryHover);
  border-color: var(--primaryHover);
  color: var(--white);
}

.text-custom-btn {
  margin-top: 4px;
  width: 200px;
}

.btn-custom-table-top-simple {
  border-top-left-radius: 12px;
  width: 160px;
  cursor: pointer;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 2px solid var(--primaryActive);
  background-color: var(--primaryActive);
  color: var(--white);
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 15px;
}

.action-btn-list {
  border: 2px solid var(--primaryActive);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0px !important;
  background-color: var(--white);
  cursor: pointer;
  width: 260px;
  position: absolute !important;
  color: var(--primaryActive);
}

button.action-btn-list-menu {
  border: 2px solid var(--primaryActive);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0px !important;
  background-color: var(--white);
  cursor: pointer;
  width: 260px;
  color: var(--primaryActive);
  height: 50px;
}

.action-btn-list svg {
  color: var(--primaryActive) !important;
}

.action-search-btn-list {
  border: 2px solid var(--primaryActive);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0px !important;
  background-color: var(--white);
  cursor: pointer;
  position: absolute !important;
  color: var(--primaryActive);
  z-index: 150;
  max-width: 200px
}

button.action-search-btn-list-menu {
  border: 2px solid var(--primaryActive);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0px !important;
  background-color: var(--white);
  cursor: pointer;
  color: var(--primaryActive);
  height: 50px;
}

.action-search-btn-list svg {
  color: var(--primaryActive) !important;
}

.btn-custom-primary {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--primaryActive);
  color: var(--white);
  padding: 15px;
  min-width: 160px;
  float: right;
  transition: all ease .15s !important;
  text-transform: uppercase;

  &:hover {
    border: 2px solid var(--primaryHover);
    background-color: var(--primaryHover);
    color: var(--white);
  }
}

.btn-custom-secondary {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--secondary);
  background-color: var(--secondary);
  background-size: 200% 100% !important;
  color: var(--white);
  padding: 17px;
  min-width: 160px;
  float: right;
  margin-right: 30px;
  background-position: right bottom !important;
  transition: all ease .15s !important;
  text-transform: uppercase;

  &:hover {
    border: 2px solid var(--primaryHover);
    background-color: var(--primaryHover);
    color: var(--white);
  }
}

.btn-custom-outlined {
  border-top-left-radius: 0;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid var(--primaryActive);
  background-color: var(--white);
  color: var(--primaryActive);
  padding: 15px;
  min-width: 160px;
  margin-left: 20px;
  transition: all ease .15s !important;
  text-transform: uppercase;

  &:hover {
    background-color: var(--primaryActive);
    color: var(--white);
  }
}


/* Layouts */
.page-footer {
  background-color: var(--primaryActive) !important;
  bottom: 0;
  width: 100%;
}

.grid-content {
  margin: auto;
  height: 19px;
  text-align: center;
}

.grid-content .div {
  height: 19px;
  width: fit-content;
  color: var(--white);
  margin: auto;
}

* {
  font-family: var(--fontFamily) !important;
}

.table-card-title {
  margin-top: 10px;
}

.grid-detailed-text {
  margin: auto;
}

.grid-detailed-text-last {
  margin-top: 15px;
}

.filter-txt-title {
  color: var(--primaryActive);
  font-size: 16px !important;
}

/**Profile View**/
.profile-card {
  font-family: var(--fontFamily) !important;
  height: fit-content;
  width: 95%;
  border-radius: 12px !important;
  margin: auto;
}

.profile-section-box {
  width: 100%;
  height: fit-content;
}

.pageBox-profile {
  height: fit-content;
  width: 100%;
  margin-bottom: 50px;
}

.profile-section-divider-box {
  padding: 20px;
}

.profile-section-header {
  font-size: 20px;
  font-weight: 800;
  padding-bottom: 5px;
}

.profile-section-header>p {
  font-size: 20px;
  font-weight: 800;
  padding-bottom: 5px;
}

.profile-icon-blue {
  color: var(--primaryActive);
  padding-top: 5px;
}


.profile-icon-attachment {
  color: var(--primaryActive);
}

.profile-icon-attachment-box {
  float: left;
  margin-right: 5px;
}

.profile-icon-attachment-link {
  cursor: pointer;
}

.profile-icon-attachment-link:hover {
  color: var(--primaryHover) !important;

}

.profile-companyLogo {
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(to bottom right, #589FE5, #435cd2);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 200px;
  height: 200px;
}

.cardMain-CompanyProfile {
  background-color: var(--white);
  width: 100%;
  height: fit-content;
  border-radius: 15px !important;
}

.doc-box {
  border: 1px solid var(--lightGrey);
  padding: 10px;
}

.doc-box-top {
  background-color: var(--primaryActive);
  color: var(--white);
  padding: 10px;
  border: 1px solid var(--lightGrey);
  font-weight: 600;
}

.MuiPagination-ul li:first-child .MuiPaginationItem-root {
  border: 2px solid var(--primaryActive) !important;
}

.MuiPagination-ul li:last-child .MuiPaginationItem-root {
  border: 2px solid var(--primaryActive) !important;
}


.MuiPagination-ul li:last-child .MuiPaginationItem-root .MuiSvgIcon-root {
  color: var(--primaryActive) !important;
}

.MuiPagination-ul li:first-child .MuiPaginationItem-root .MuiSvgIcon-root {
  color: var(--primaryActive) !important;
}


/* TopNavigation.css */

.top-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: var(--primaryActive);
  color: white;
}

.logo {
  font-size: 1.5rem;
}

.menu-icon {
  display: none;
  /* Hide by default on larger screens */
  cursor: pointer;
  font-size: 1.8rem;
}

.nav-links,
.nav-links-outside {
  display: flex;
  list-style-type: none;
}

.nav-links li,
.nav-links-outside li {
  margin-right: 40px;
}

.nav-links a,
.nav-links span {
  color: white;
  text-decoration: none;
}

.desktop-only {
  display: flex;
  /* Show these items only on larger screens */
}

.top-navigation span:hover {
  cursor: pointer !important;
}


#navigation-badge,
#navigation-badge-message {

  margin-top: 5px;
}



@media screen and (max-width: 1568px) {
  .menu-icon {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
  }

  .top-navigation {
    justify-content: left;
  }

  #positioned-menu-office,
  #positioned-menu,
  #positioned-menu-company {
    margin-left: 200px !important;
    margin-top: 0px !important;
  }

  .nav-links-outside li {
    margin-right: 30px;
    cursor: pointer;
  }


  .nav-links {
    position: absolute;
    top: 0%;
    /* Position below the main navigation */
    left: 0;
    background-color: var(--primaryActive);
    width: "fit-content";
    padding: 20px;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
  }

  .mobile {
    flex-direction: column;
    transform: translateY(0);
    top: 9%;
  }

  .nav-links li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  #navigation-badge-message {
    margin-left: 100px;
    margin-top: 5px;
  }

  #navigation-badge {
    margin-right: 100px;
    margin-top: 5px;
  }

  .nav-links-outside {
    margin-left: auto;
  }
}



.btn-custom-download-pdf {
  background-color: var(--secondary) !important;
  color: var(--white) !important;
}

#positioned-menu,
#positioned-menu-company,
#positioned-menu-office {
  margin-top: 35px;
}



.focused-header {
  color: #ffffffab;
}

#positioned-menu-notification {
  margin-top: 40px;
  margin-left: -100px;
}

#positioned-menu-message {
  margin-top: 41px;
  margin-left: -100px;
}

.messaging-box {
  width: 400px;
  height: 400px;
  overflow: scroll;
  padding: 10px;
}

.button-box-notification {
  padding-top: 15px;
  float: left;
}

#accept-button {
  background-color: var(--primaryActive);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  text-transform: none !important;
}


#contact-button {
  background-color: var(--primaryActive);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 14px;
  width: 100%;
  text-transform: none !important;
}

#reject-button {
  background-color: var(--secondary);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  margin-left: 5px;
  text-transform: none !important;
}

#navigation-companyCategory {
  width: fit-content;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  border-radius: 8px;
  background-color: var(--secondary);
  color: var(--white);
}

.company-details-box {
  margin-top: -12px;
}