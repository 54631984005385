.companyProfileBox {
  width: 98%;
  margin: auto;
  height: fit-content;
  padding-bottom: 20px;
}

.beePostBox {
  margin-top: 15px;
  width: 100%;
  height: fit-content;
}

.beeHeading {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn {
  margin-bottom: 10px !important;
  background-color: #435cd2 !important;
}

.btnIcon {
  margin-right: 10px;
}

.companyProfileSideBar {
  width: 20%;
  margin-right: 1%;
  margin-top: 20px;
  float: left;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  height: fit-content;
  border-radius: 4px;
}

.firstAccord {
  margin-top: 15px !important;
  box-shadow: none !important;
}

.companyProfileContent {
  width: 79%;
  margin-top: 5px;
  height: fit-content;
  float: left;
}

.postBody {
  width: 100%;
  height: fit-content;
}

.postBeeGrouping {
  margin-top: 40px;
  width: 98%;
  height: fit-content;
}

.postInfo {
  margin-bottom: 20px;
}

.editSave {
  font-size: 25px;
  color: #0177b7;
  text-align: right;
  width: 100%;
  padding-right: 5px;
}

.btnBox {
  width: 10%;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 5px;
  height: fit-content;
}

.btnBoxContact {
  width: 100%;
  height: fit-content;
}

.btnBoxLeft {
  width: 33%;
}

.btnBoxCenter {
  width: 33%;
}

.btnBoxRight {
  width: 33%;
}

.item {
  width: 100%;
  margin-bottom: 20px;
  height: fit-content;
}

.postBody .MuiFormControl-root {
  width: 100% !important;
}

/*new*/
.box {
  width: 100%;
  height: 190px;
}

.companyViewBox {
  margin-top: 15px;
  height: 130px;
  width: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 10px;
  background-color: #435cd2;
}

.imageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 10px;
}

.companyDetailsHeader {
  width: 100%;
  padding: 10px;
  margin-top: 160px;
  color: white;
  background-color: #435cd2;
}

.companyViewLeft {
  height: 90px;
  padding-top: 40px;
  color: white;
  float: left;
}

.companyViewRight {
  height: 90px;
  width: 200px;
  float: left;
}

.companyProfileImage {
  height: 150px;
  width: 150px;
  z-index: -99;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.pageLayout {
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  height: fit-content;
  margin-bottom: 20px;
  border-radius: 4px;
}

.hoverCloseCompany:hover {
  color: #ffffff !important;
  background-color: #b82828 !important;
}

.hoverCloseCompany {
  padding: 2px !important;
  float: right;
  background-color: #fff !important;
  margin-right: -5px !important;
  margin-top: 5px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}