.tableSubSection {
  width: 100%;
  text-align: left;
}

.tableSubSection th {
  width: 20px;
}

.subSectionBox {
  Width: 100%;
  height: fit-content;
  position: absolute;
  padding-bottom: 30px !important;
}

.subSection {
  margin: auto;
  width: 95.5%;
  height: fit-content;
}