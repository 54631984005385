.listItemButton {
  width: 100%;
  padding: 10px;
}

.listWrap {
  width: 100%;
  height: fit-content;
  margin-bottom: 10px;
}


.listWrapHeader {
  width: 100%;
  padding: 10px;
  height: fit-content;
}

.paper {
  width: 100%;
  padding-top: 10px;
  height: fit-content;
  box-shadow: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid lightgray;
  border-radius: 0px !important;
}


.paperHeading {
  margin-top: 10px;
  color: white !important;
  background-color: #435cd2 !important;
}

.container {
  margin-top: 0px !important;
  width: 100% !important;
  margin-left: 0px !important;
}


.headingEachBox {
  width: 100%;
  height: fit-content;
}

.headingEachBoxLeft {
  float: left;
  width: 30px;
}

.headingEachBoxRight {
  float: left;
  padding-top: 2px;
  width: calc(100% - 30px);
}

.paper .container span {
  white-space: normal;
  word-break: break-word;
  font-size: 14px !important;
}

.userManagementContainer {
  margin-top: 0px !important;
  width: 100% !important;
  margin-left: 0px !important;
}