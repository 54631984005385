@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.post__header_top {
    width: 100%;
    border-bottom: 1px dashed lightgray;
    margin-bottom: 10px;
    padding-bottom: 10px;
}


.last-verification {
    color: lightgrey;
    text-align: right;
    padding-right: 10px;
}


.last-verification-followup {
    color: rgb(71, 70, 70);
    text-align: right;
    padding-top: 10px;
    padding-right: 10px;
}




.title-profile {
    margin-left: 10px;

}

.swal2-container {
    z-index: 1400 !important;
}